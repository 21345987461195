import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ThemeContext from "../context/ThemeContext"
import Layout from '../components/Layout'

export class TheIndexPageTemplate extends React.Component {
  componentDidMount() {
    this.context.hideNav()
  }

  createArrayAtoZ = () => {
    return Array
      .apply(null, {length: 26})
      .map((x, i) => {return (
        {
          letter: String.fromCharCode(65 + i),
          stories: []
        }
      )})
  }

  createIndex = (stories) => {
    let abcArray = this.createArrayAtoZ()
    if (stories) {
      stories.forEach(story => {
        const firstLetter = story.node.frontmatter.title.charAt(0)
        const charIndex = abcArray.findIndex(char => char.letter === firstLetter.toUpperCase())
        if (charIndex >= 0) {
          abcArray[charIndex].stories.push(story)
        }
      })
    }
    abcArray = abcArray.filter(char => char.stories.length > 0)
    return abcArray
  }

  render() {
    const { stories, text, title, prevPage } = this.props
    const index = this.createIndex(stories)
    return (
      <ThemeContext.Consumer>
        {theme => (
          <div className="cTheIndex cSplitScreen">
            <div className="cSplitScreen-content">
              <div className="cSplitScreen-heading">
                <div className="cSplitScreen-icon">
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <p className="cSplitScreen-title">Cleo's Books</p>
                <AniLink fade to={prevPage ? prevPage : '/'} duration={0.25} className="close-icon" aria-label="Close"></AniLink>
              </div>
              <div className="cSplitScreen-path">
                <div className="cSplitScreen-icon">
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <p className="cSplitScreen-path-text"><AniLink fade duration={0.25} to="/menu">Menu</AniLink> / {title ? title : 'The Index'}</p>
                <AniLink fade to={prevPage ? prevPage : '/'} duration={0.25} className="close-icon" aria-label="Close"></AniLink>
              </div>
              <div className="cTheIndex-content">
                <div className="cTheIndex-description">
                  <p>{text}</p>
                </div>
                <div className="cTheIndex-list">
                  {index && index.length > 0 ? index.map((char, index) => {
                    return (
                      <div className="cTheIndex-item" key={`${char.letter} - ${index}`}>
                        <p className="cTheIndex-character">{char.letter}</p>
                        {char.stories.map((story, index) => {
                          return (
                            <AniLink
                              key={`${kebabCase(story.node.frontmatter.title)} - ${index}`}
                              className="cTheIndex-link link"
                              fade to={story.node.fields.slug ? story.node.fields.slug : '/'}
                              duration={0.25}
                              onClick={() => theme.updatePrevPath(window.location.pathname)}
                            >
                            {story.node.frontmatter.title}
                            </AniLink>
                          )
                        })}
                      </div>
                    )
                  }) : null}
                </div>
              </div>
            </div>
            <AniLink className="cSplitScreen-cover" fade to={prevPage ? prevPage : '/'} duration={0.25}>
              <p className="cSplitScreen-cover-title">Cleo's Books</p>
            </AniLink>
          </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

TheIndexPageTemplate.contextType = ThemeContext

TheIndexPageTemplate.propTypes = {
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        frontmatter: PropTypes.shape({
          title: PropTypes.string
        }),
        fields: PropTypes.shape({
          slug: PropTypes.string
        })
      })
    })
  ),
  text: PropTypes.string,
  title: PropTypes.string
}

const TheIndexPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  const text = data.markdownRemark.frontmatter.text
  const title = data.markdownRemark.frontmatter.title
  return (
    <Layout>
      <TheIndexPageTemplate
        stories={edges}
        text={text}
        title={title}
      />
    </Layout>
  )
}

TheIndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default TheIndexPage

export const pageQuery = graphql`
  query TheIndexPageTemplate($id: String!) {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "story-template"}}}, sort: {order: ASC, fields: frontmatter___title}) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        text
        title
      }
    }
  }
`
